import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const Privacy = () => (
  <Layout>
    <Helmet title="Privacy policy | Rudd Joinery">
      <meta name="description" content="" />
    </Helmet>
    <div className="section">
      <div className="wrapper">
        <div class="story-block story">
          <h1>Privacy</h1>
          <p>
            This privacy policy sets out how Rudd Joinery Limited uses and
            protects any information that you supply when you use this website.
            Rudd Joinery Limited is committed to ensuring that your privacy is
            protected. Should we ask you to provide certain information by which
            you can be identified when using this website, then you can be
            assured that it will only be used in accordance with this privacy
            statement. Rudd Joinery Limited may change this policy from time to
            time by updating this page. You should check this page from time to
            time to ensure that you are happy with any changes. This policy is
            effective from 19th July 2018.
          </p>

          <h3>What we collect</h3>

          <p>
            We may collect the following information: Your name, Contact
            information including email address, demographic information such as
            postcode, preferences and interests, other information relevant to
            customer surveys and/or offers.
          </p>

          <h3>What we do with the information we gather</h3>

          <p>
            We require this information to understand your needs and provide you
            with a better service. We may periodically send promotional emails
            about new products, special offers or other information which we
            think you may find interesting using the email address which you
            have provided. From time to time, we may also use your information
            to contact you for market research purposes. We may contact you by
            email, phone or mail. We may use the information to customise the
            website according to your interests.
          </p>

          <h3>Security</h3>

          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>

          <h3>Links to other websites</h3>

          <p>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>

          <h3>Controlling your personal information</h3>

          <p>
            You may choose to restrict the collection or use of your personal
            information in the following ways: Whenever you are asked to fill in
            a form on the website, look for the box that you can click to
            indicate that you do not want the information to be used by anybody
            for direct marketing purposes. If you have previously agreed to us
            using your personal information for direct marketing purposes, you
            may change your mind at any time by writing to or emailing us at{' '}
            <a href="mailto:info@ruddjoinery.co.uk">info@ruddjoinery.co.uk</a>.
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so.
          </p>

          <p>
            You may request details of personal information which we hold about
            you under the Data Protection Act 1998. A small fee will be payable.
            If you would like a copy of the information held on you please write
            to{' '}
            <strong>
              Rudd Joinery,&nbsp;Sculthorpe Boulevard, Tatterset Business Park,
              Fakenham, Norfolk, NR21 7TH
            </strong>
            .
          </p>

          <p>
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible and we will promptly correct any information found to be
            incorrect.
          </p>
        </div>
      </div>
    </div>
    <div className="section">
      <div className="wrapper">
        <div class="story-block story">
          <h2>Cookies</h2>
          <p>
            A cookie is a small file which asks permission to be placed on your
            computer’s hard drive. The cookie helps analyse web traffic or lets
            you know when you visit a particular website. Cookies allow web
            applications to respond to you as an individual. The web application
            can tailor its operations to your needs, likes and dislikes by
            gathering and remembering information about your preferences.
          </p>

          <p>
            We use traffic log cookies to identify which pages are being used.
            This helps us analyse data about webpage traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
          </p>

          <p>
            Overall, cookies help us provide you with a better website by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>

          <p>
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Privacy
